<template>
    <component :is="linked ? Link : 'div'" :href="route('listing.show', {listing: listing.id, slug: listing.slug})" as="button" type="button" class="text-left">
        <div class="flex gap-x-4">
            <div class="flex-none">
                <Link :href="route('user.show', {user: listing.user.username })">
                    <img :src="listing.user.avatar" :alt="`${listing.user.name}'s Avatar`" class="h-10 w-10 sm:h-12 sm:w-12 rounded-md hover:opacity-80 transition duration-300 ease-in-out">
                </Link>
            </div>
            <div class="flex-grow">
                <div class="flex justify-between mb-1.5">
                    <Link :href="route('user.show', {user: listing.user.username })" class="leading-none flex items-baseline gap-x-1.5">
                        <span class="font-bold text-gray-800 hover:underline hover:decoration-yellow-500">
                            {{ listing.user.name }}
                        </span>
                        <span class="text-sm text-gray-600">
                            @{{ listing.user.username }}
                        </span>
                    </Link>
                    <div v-if="menuItems.length" class="-mr-4 -mt-1.5">
                        <Menu as="div" class="relative inline-block text-left">
                            <div>
                                <MenuButton :as="Button" variant="ghost">
                                    <EllipsisVerticalIcon class="w-4 h-4 text-gray-900 cursor-pointer"
                                                          aria-hidden="true"/>
                                </MenuButton>
                            </div>

                            <transition enter-active-class="transition ease-out duration-100"
                                        enter-from-class="transform opacity-0 scale-95"
                                        enter-to-class="transform opacity-100 scale-100"
                                        leave-active-class="transition ease-in duration-75"
                                        leave-from-class="transform opacity-100 scale-100"
                                        leave-to-class="transform opacity-0 scale-95">
                                <MenuItems
                                    class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white border border-gray-200e">
                                    <div class="py-1">
                                        <MenuItem
                                            as="div"
                                            v-for="item in menuItems"
                                            :key="`listing_menu_item_${item.text}`"
                                            v-slot="{ active }"
                                        >
                                            <Link
                                                v-if="item.type === 'link'"
                                                :href="item.href"
                                                :class="[active ? 'bg-yellow-100 text-yellow-900' : 'text-gray-700', 'block px-4 py-2 text-sm transition duration-300 ease-in-out']"
                                            >
                                                {{ item.text }}
                                            </Link>
                                            <button
                                                v-if="item.type === 'button' && item.color === 'danger'"
                                                @click="item.action"
                                                :class="[active ? 'bg-red-100 text-red-900' : 'text-red-700', 'block px-4 py-2 text-sm w-full text-left transition duration-300 ease-in-out']"
                                            >
                                                {{ item.text }}
                                            </button>
                                        </MenuItem>
                                    </div>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
                <div class="mb-3">
                    <Link :href="route('listing.show', {listing: listing.id, slug: listing.slug})">
                        <Heading size="h2" class="text-gray-800 tracking-tight leading-tight font-extrabold">
                            {{ listing.title }}
                        </Heading>
                    </Link>
                </div>
                <div class="mb-5">
                    <div class="flex gap-x-1.5 flex-wrap gap-y-1">
                        <Link :href="addTag('types', tag.id)"
                              v-for="tag in listing.types" :key="`tag_${tag.id}`"
                        >
                            <Badge color="cyan">
                                {{ tag.name.toLowerCase() }}
                            </Badge>
                        </Link>
                        <Link :href="addTag('categories', tag.id)"
                              v-for="tag in listing.categories" :key="`tag_${tag.id}`"
                        >
                            <Badge color="fuchsia">
                                {{ tag.name.toLowerCase() }}
                            </Badge>
                        </Link>
                        <Link :href="addTag('compensation', tag.id)"
                              v-for="tag in listing.compensation" :key="`tag_${tag.id}`"
                        >
                            <Badge color="teal">
                                {{ tag.name.toLowerCase() }}
                            </Badge>
                        </Link>
                        <Link :href="addTag('skills', tag.id)"
                              v-for="tag in listing.skills" :key="`tag_${tag.id}`"
                        >
                            <Badge color="pink">
                                {{ tag.name.toLowerCase() }}
                            </Badge>
                        </Link>
                    </div>
                </div>
                <div class="text-gray-800 prose prose-sm prose-yellow max-w-full mb-4"
                     v-html="excerpt ? listing.excerpt_html : listing.body_html"/>
            </div>
        </div>
    </component>
</template>
<script setup>
import {Link, router, usePage} from "@inertiajs/vue3";
import Badge from "@/Components/Badge.vue";
import {EllipsisVerticalIcon} from "@heroicons/vue/24/outline";
import Button from "@/Components/Button.vue";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {useConfirm} from "@/Components/Confirm/confirm.js";
import {can} from "@/permissions.js";
import {computed} from "vue";
import Heading from "@/Components/Heading.vue";
import {addTag} from "../addTag.js";

const props = defineProps({
    listing: {
        type: Object,
    },
    excerpt: {
        type: Boolean,
    },
    linked: {
        type: Boolean,
        default: true,
    }
});

const page = usePage();
const user = computed(() => page.props.auth.user);

const menuItems = [
    ...can('listing.edit', props.listing, user.value) ? [{
        type: 'link',
        text: 'Edit',
        href: route('listing.edit', {listing: props.listing.id, slug: props.listing.slug}),
    }] : [],
    ...can('listing.delete', props.listing, user.value) ? [{
        type: 'button',
        color: 'danger',
        text: 'Delete',
        action: () => deleteListing(),
    }] : [],
];

const confirmDeleteListing = useConfirm(() => {
    router.delete(route('listing.destroy', {
        listing: props.listing.id,
        slug: props.listing.slug
    }), {
        preserveScroll: true,
    });
}, "Are you sure you want to delete this listing? The action cannot be undone.");

const deleteListing = () => {
    confirmDeleteListing();
}
</script>

<style>
.embedded-content {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    @apply rounded-md border border-gray-300;
}

.embedded-content iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.prose img {
    @apply rounded-md border border-gray-300;
}
</style>
