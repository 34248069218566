const permissions = {
    listing: {
        edit: (listing, user) => {
            return listing.user_id === user.id;
        },
        delete: (listing, user) => {
            return listing.user_id === user.id;
        },
    },
    reply: {
        edit: (reply, user) => {
            return reply.user_id === user.id;
        },
        delete: (reply, user) => {
            return reply.user_id === user.id;
        },
    }
};

export const can = (permission, resource, user) => {
    if (!user) {
        return false;
    }

    if (user.type === 'administrator' || user.type === 'moderator') {
        return true;
    }

    const check = permission
        .split('.')
        .reduce((accumulator, currentValue) => accumulator[currentValue], permissions);

    return check(resource, user);
};
